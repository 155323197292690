<template>
  <!-- 订单进度 -->
  <div class="main">
    <van-steps
      direction="vertical"
      active-color="#BCA675"
      inactive-color="#F4F0E7"
      :active="length - 1"
    >
      <van-step v-for="(item, index) in orderprogress" :key="index">
        <template slot="inactive-icon">
          <van-icon name="checked" color="#F4F0E7" />
        </template>
        <template slot="finish-icon">
          <van-icon name="checked" color="#BCA675" />
        </template>
        <template slot="active-icon">
          <van-icon name="checked" color="#BCA675" />
        </template>
        <div v-if="index <= length - 1">
          <p style="color: black; margin-bottom: 10px; font-weight: bold">
            {{ item.title }}
          </p>
          <p class="actp" style="color: #bca675">{{ item.describe }}</p>
          <p class="actp">{{ item.result }}</p>
          <p class="actp" v-if="index != 0">结果：{{ item.sonType }}</p>
          <p class="actp" v-if="index != 0">审批时间：{{ item.time }}</p>
          <p class="actp" v-else>申请时间：{{ item.time }}</p>
          <div style="white-space: pre-wrap" class="actp">
            {{ item.content }}
          </div>
        </div>
        <div v-else class="Type">
          <p style="margin-bottom: 10px; font-size: 14px">{{ item.title }}</p>
          <p>{{ item.describe }}</p>
          <p>{{ item.result }}</p>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
import { logs } from "../../api/order/order";
export default {
  data() {
    return {
      orderId: "",
      // 订单进度
      orderprogress: [
        {
          title: "申请",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "初审",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "下户",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "复审",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "终审",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "面签",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "权证",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "客服",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "总经办",
          result: "",
          time: "",
          content: "",
        },
        {
          title: "放款",
          result: "",
          time: "",
          content: "",
        },
      ],
      // 新订单进度
      avitorder: [],
      length: 0,
    };
  },
  methods: {
    // 获取订单进度
    getlogs() {
      logs({ orderId: this.orderId }).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data);
          this.avitorder = res.data.data;
          for (let i = 0; i < this.avitorder.length; i++) {
            this.orderprogress[i].describe = this.avitorder[i].type;
            this.orderprogress[i].title = this.avitorder[i].state;
            this.orderprogress[i].result = this.avitorder[i].result;
            this.orderprogress[i].sonType = this.avitorder[i].sonType;
            this.orderprogress[i].time = this.avitorder[i].updatedAt;
            // 给后台返回的内容更换换行
            if (this.avitorder[i].content) {
              this.orderprogress[i].content = this.avitorder[i].content.replace(
                /\r/g,
                "\n\r"
              );
            }
            if (this.avitorder[i].id) {
              this.length++;
            }

            // 第一个 申请字段 不需要结果信息
            if (i == 0) {
              this.orderprogress[i].content = "";
              this.orderprogress[i].sonType = "";
            }
          }
        }
        console.log(this.orderprogress);
      });
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.getlogs();
  },
};
</script>

<style scoped>
.main {
  width: 90%;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding: 5%;
}
.actp {
  color: black;
  font-size: 12px;
}
.Type p {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}
</style>
