import axios from "../index";

// 订单记录列表
export function getManagerOrderGzhList(data) {
  return axios.postForm("/bapi/managerBusiness/getManagerOrderGzhList", data);
}

// 录单详情信息
export function typeInOrderInfo(data) {
  return axios.get("/bapi/managerBusiness/typeInOrderInfo", data);
}

// 订单进度
export function logs(data) {
  return axios.get("/bapi/managerBusiness/logs", data);
}
